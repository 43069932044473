import "./App.css";
// import logo from "./logo_raw.jpg";
// import logo from "./logo-secondary-round.png";
import logo from "./logo-round.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img className="App-logo" src={logo} />
        <p>Coming Soon...</p>
      </header>
      {/* <footer className="App-footer">Contact</footer> */}
    </div>
  );
}

export default App;
